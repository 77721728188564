import Head from 'next/head';
import Script from 'next/script';

import { NextSeo } from 'next-seo';
import PropTypes from 'prop-types';

import AssembleLayout from '@/src/layouts/AssembleLayout';
import MainLayout from '@/src/layouts/MainLayout';
import IndexView from '@/src/views/root/IndexView';

function HomePage({ doc = undefined, preview = undefined }) {
  return (
    <AssembleLayout Layout={MainLayout} Component={IndexView} doc={doc} preview={preview}>
      <NextSeo
        title="Tudo para educação. Educação para todos"
        description="Conheça a maior plataforma de e-commerce e marketplace para o setor educacional."
        openGraph={{
          title: 'Tudo para educação. Educação para todos',
        }}
      />
      <Head>
        {process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && (
          <Script id="clarity" strategy="afterInteractive">
            {typeof window !== 'undefined' &&
              (function (c, l, a, r, i, t, y) {
                c[a] =
                  c[a] ||
                  function () {
                    (c[a].q = c[a].q || []).push(arguments);
                  };
                t = l.createElement(r);
                t.async = 1;
                t.src = `https://www.clarity.ms/tag/${i}`;
                y = l.getElementsByTagName(r)[0];
                y.parentNode.insertBefore(t, y);
              })(window, document, 'clarity', 'script', 'or9lhr6jcf')}
          </Script>
        )}
        <link rel="preload" href="/images/illustrations/home/hero.svg" as="image" />
      </Head>
    </AssembleLayout>
  );
}

HomePage.propTypes = {
  doc: PropTypes.object,
  preview: PropTypes.bool,
};

export default HomePage;
